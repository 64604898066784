<template>
  <div class="notfound">您访问的页面不见了！</div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {
    document.title = '404';
  }
};
</script>

<style scoped lang="scss">
.notfound {
  padding: 20px;
  font-size: 20px;
  color: #191919;
  padding-bottom: 16px;
}
</style>
