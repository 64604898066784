<template>
  <div class="head">
    <div class="box">
      <div class="icon" @click="goXznkjzx('/')">
        <img src="@/assets/images/xzl.png" alt="" />
      </div>
      <div class="space"></div>
      <div class="nav" @click="goXznkjzx('/index.html')">网站首页</div>
      <div class="nav" @click="goXznkjzx('/list-1-0.html')">视频软件</div>
      <div class="nav" @click="goXznkjzx('/list-2-0.html')">音乐软件</div>
      <div class="nav" @click="goXznkjzx('/list-3-0.html')">系统软件</div>
      <div class="nav" @click="goXznkjzx('/list-4-0.html')">下载工具</div>
      <div class="search">
        <div class="ctx">
          <label for="input">
            <input type="text" v-model="input" />
          </label>
        </div>
        <div class="btn" @click="search">搜索</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      input: ''
    };
  },
  methods: {
    goXznkjzx(params) {
      window.open(`http://www.xznkjzx.cn${params}`, '_blank');
    },
    search() {
      window.open(`http://xz.bzyinxiao.com/index.php?m=search&a=index&c=index&searchkey=${this.input}`);
    }
  }
};
</script>

<style scoped lang="scss">
.head {
  width: 100%;
  height: 80px;
  background: #ffffff;
  .box {
    width: 1200px;
    height: 80px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    .icon {
      width: 114px;
      height: 80px;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .space {
      flex: 1;
    }
    .nav {
      padding: 0 10px;
      cursor: pointer;
      color: #000;
      font-size: 16px;
    }
    .search {
      width: 218px;
      height: 30px;
      display: flex;
      .ctx {
        width: 150px;
        height: 30px;
        border: 1px solid #008ded;
        input {
          width: 140px;
          height: 28px;
          text-indent: 10px;
          margin: 0 auto;
          border: none;
          outline: none;
        }
      }
      .btn {
        width: 60px;
        height: 32px;
        background: #008ded;
        cursor: pointer;
        text-align: center;
        line-height: 32px;
        font-size: 18px;
        color: #ffffff;
      }
    }
  }
}
</style>
