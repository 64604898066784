import Vue from 'vue';
import VueRouter from 'vue-router';
import Layout from '../views/layout.vue';
import Notfound from '../views/not-found.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/notfound',
    component: Notfound
  },
  {
    path: '/:id',
    component: Layout
  },
  {
    path: '*',
    redirect: '/notfound'
  }
];

const router = new VueRouter({
  routes,
  mode: 'history'
});

export default router;
