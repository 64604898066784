// 软件配置地址
export const APP_LIST_ADDR = {
  lds: 'https://cdn-cms.apkevery.com/cms/project_118/app_list_lds.json',
  joyfartech: 'https://cdn-cms.joyfartech.com/cms/project_118/app_list_ldy.json'
};

// 下载器地址
export const DOWNLOADER = '//cdn-file.joyfartech.com/FreeUp/FreePack.exe';

// accessview下载地址
export const ACCESSVIEW_DOWNLOAD = {
  x64: 'https://cdn-file.tjfytech.com/AccessView/AccessViewSetup64_1.0.0.1060_channel_bd_sun.exe',
  x32: 'https://cdn-file.tjfytech.com/AccessView/AccessViewSetup32_1.0.0.1060_channel_bd_sun.exe'
};

// lds打点地址
export const LDSCOUNT_ADDR = '//s.apkevery.com';

// joyfartech打点地址
export const JOYCOUNT_ADDR = '//s.joyfartech.com';

// lds请求地址
export const LDSREQ_ADDR = 'https://base.apkevery.com';

// joyfartech请求地址
export const JOYREQ_ADDR = 'https://base.joyfartech.com';
