<template>
  <div class="layout">
    <Head v-if="headShow" />
    <HeadFree v-if="headFreeShow" />
    <div class="content">
      <div
        class="top"
        :style="{
          background: `url(${detail.web_background_image})`
        }"
      >
        <div class="one">
          <img src="@/assets/images/top_icon.png" alt="" />
        </div>
        <div class="two">
          <div class="left">
            <el-carousel indicator-position="outside" height="350px">
              <el-carousel-item v-for="item in detail.lb_imgs" :key="item">
                <img :src="item" alt="" srcset="" />
              </el-carousel-item>
            </el-carousel>
          </div>
          <div :class="['right', theme]">
            <div class="first">
              <img :src="detail.icon" alt="" />
              <p>{{ detail.appname }}</p>
              <div class="number">
                <p>下载次数</p>
                <p>{{ detail.download_times || 0 }}次</p>
              </div>
            </div>
            <div class="second">
              <p><span>软件授权：</span><span>免费软件</span></p>
              <p>
                <span>软件分类：</span><span>{{ detail.category }}</span>
              </p>
              <p>
                <span>更新时间：</span><span>{{ detail.update_time }}</span>
              </p>
              <p>
                <span>软件大小：</span><span>{{ detail.pkg_size }}</span>
              </p>
              <p>
                <span>软件版本：</span><span>V{{ detail.version }}</span>
              </p>
              <p>
                <span>软件系统：</span><span>{{ detail.platform }}</span>
              </p>
            </div>
            <div class="fourth">
              <img src="@/assets/images/down.png" alt="" />
              <span @click.stop="download('point')">立即下载</span>
            </div>
            <div class="fifth">通过鲁大师应用市场下载，感受更快速、更安全的下载体验</div>
            <div class="sixth"></div>
          </div>
        </div>
      </div>
      <div class="tag">
        <img :src="detail.web_middle_image" alt="" />
      </div>
      <div class="copyright">
        <p>本网站的图文和软件来自于网络，如有内容侵犯您的合法权益，请及时与我们联系，我们将第一时间安排删除。</p>
        <p class="name">{{ copyright.name }}</p>
        <p class="addr">{{ copyright.addr }}</p>
      </div>
    </div>
    <template v-if="appid === 'sunloginav'">
      <div class="bottomav" @click="bdownload">
        <div class="box">
          <div class="left">
            <img src="@/assets/images/iconav.png" alt="" />
          </div>
          <div class="mid">
            <img src="@/assets/images/iconbmid.png" alt="" />
          </div>
          <div class="right" @click="bdownload('point')">
            <img src="@/assets/images/download.gif" alt="" />
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="bottom">
        <div class="box">
          <div class="left">
            <img :src="detail.icon" alt="" />
            <span>{{ detail.appname }}</span>
          </div>
          <div class="mid">{{ detail.slogan }}</div>
          <div class="right">
            <img src="@/assets/images/down.png" alt="" />
            <span @click.stop="download('point')">立即下载</span>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
/**
 * 区分不同域名
 * sunloginav 特殊处理
 */
import Head from '@/components/com-head.vue';
import HeadFree from '@/components/com-head-free.vue';
import Axios from 'axios';
import Cookies from 'js-cookie';
import {
  DOWNLOADER,
  ACCESSVIEW_DOWNLOAD,
  APP_LIST_ADDR,
  LDSCOUNT_ADDR,
  JOYCOUNT_ADDR,
  LDSREQ_ADDR,
  JOYREQ_ADDR
} from '@/lib/constant';

export default {
  name: 'ldsLayout',
  components: {
    Head,
    HeadFree
  },
  data() {
    return {
      loading: false,
      headShow: false,
      headFreeShow: false,
      isldstg: false,
      copyright: {
        name: '',
        addr: ''
      },
      app_list_url: '', // cms配置地址
      appid: '', // cms配置appid
      bid: '', // 百度bid
      overdueFilter_list: [{ hostn: 'lds.xznkjzx.cn', url: 'todesk' }],
      theme: 'white',
      detail: {
        web_background_image: ''
      },
      urls: {
        downloader: DOWNLOADER,
        lds_down_url: '',
        sunloginav: ''
      }
    };
  },
  created() {
    const {
      params: { id },
      query: { bd_vid }
    } = this.$route;
    this.appid = id;
    this.bid = bd_vid || '';
    if (window.location.hostname === 'lds.xznkjzx.cn') {
      this.app_list_url = APP_LIST_ADDR.lds;
      this.copyright = {
        name: '无锡星之凝科技咨询有限公司 版权所有 备案号: 苏ICP备2023028484号-1',
        addr: '联系我们：无锡市新吴区菱湖大道200-16（D2）栋600-927'
      };
      this.headShow = true;
      this.isldstg = true;
      this.getLdstgUrl();
    }
    if (window.location.hostname === 'lds.shriwei-gpt.cn') {
      this.app_list_url = APP_LIST_ADDR.lds;
      this.copyright = {
        name: '上海日维网络科技有限公司 版权所有 备案号: 沪ICP备2023004882号-20',
        addr: '联系我们：上海市闵行区中春路988号11幢2楼'
      };
      this.isldstg = true;
      this.getLdstgUrl();
    }
    if (window.location.hostname === 'landing.joyfartech.com') {
      this.app_list_url = APP_LIST_ADDR.joyfartech;
      this.copyright = {
        name: '天津欣远科技有限公司 版权所有 备案号: 津ICP备2022000179号-1',
        addr: '联系我们：天津华苑产业区海泰西路18号北2-204工业孵化-5-1232'
      };
    }
    if (window.location.hostname === 'free.sengfeng.cn') {
      this.app_list_url = APP_LIST_ADDR.joyfartech;
      this.copyright = {
        name: '合肥水花网络科技有限公司 版权所有 备案号: 皖ICP备2022004577号-3',
        addr: '联系我们：中国（安徽）自由贸易试验区合肥市高新区习友路3333号中国（合肥）国际智能语音产业园研发中心楼508-1室'
      };
      this.headFreeShow = true;
    }
    // 推广域名sunloginav
    if (this.appid === 'sunloginav') {
      const agent = window.navigator.userAgent.toLowerCase();
      if (agent.indexOf('win64') >= 0 || agent.indexOf('wow64') >= 0) {
        this.urls.sunloginav = ACCESSVIEW_DOWNLOAD.x64;
      } else {
        this.urls.sunloginav = ACCESSVIEW_DOWNLOAD.x32;
      }
    }
  },
  mounted() {
    // 请求软件数据
    Axios.get(this.app_list_url).then(
      res => {
        const detail = res.data.find(item => item.appid === this.appid);
        if (detail) {
          const { appname, web_favicon, web_exts } = detail;
          this.detail = detail;
          if (web_exts && web_exts.font_theme) {
            this.theme = web_exts.font_theme === 1 ? 'dark' : 'white';
          }
          document.title = appname;
          const faviconLink = document.querySelector('#favicon');
          faviconLink.href = web_favicon;
        } else {
          this.$router.push('/notfound');
        }
      },
      () => {
        this.$router.push('/notfound');
      }
    );
    this.setCount('show');
  },
  methods: {
    // 获取鲁大师推广软件下载地址
    getLdstgUrl() {
      const formdata = new FormData();
      formdata.append('app', this.appid);
      formdata.append('url', encodeURI(window.location.href));
      // sunlonginav特殊处理
      if (this.appid === 'sunloginav') {
        formdata.append('tg_v', 2);
      }
      Axios.post(`${LDSREQ_ADDR}/ldstg/getUrl`, formdata).then(
        res => {
          const { errno, data } = res.data;
          if (errno === 0) {
            this.urls.lds_down_url = data.url;
          }
        },
        () => {}
      );
    },
    // 下载
    download(params) {
      if (this.isldstg) {
        this.download_lds(params);
      } else {
        this.download_joyfartech(params);
      }
    },
    download_lds(params) {
      const flag = this.isOverdueFilter(params);
      if (flag) return;
      if (!this.urls.lds_down_url) return;
      if (this.loading) return;
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 1000);
      if (params === 'point') {
        this.setPoint();
      }
      const elt = document.createElement('a');
      elt.setAttribute('href', this.urls.lds_down_url);
      elt.setAttribute('download', '');
      elt.style.display = 'none';
      document.body.appendChild(elt);
      elt.click();
      this.setCount('click');
      document.body.removeChild(elt);
    },
    download_joyfartech(params) {
      if (params !== 'point') return;
      if (this.loading) return;
      this.loading = true;
      const { appname, appid } = this.detail;
      this.setCount('click');
      if (params === 'point') {
        this.setPoint();
      }
      Axios.get(this.urls.downloader, {
        responseType: 'blob'
      }).then(
        res => {
          this.loading = false;
          const url = window.URL.createObjectURL(res.data);
          const filename = `${appname}__${appid}__${this.bid ? 'baidu' : 'db'}.exe`;
          if (window.navigator.msSaveOrOpenBlob) {
            navigator.msSaveOrOpenBlob(res.data, filename);
          } else {
            const a = document.createElement('a');
            a.href = url;
            a.download = filename;
            a.click();
          }
        },
        () => {
          this.loading = false;
        }
      );
    },
    bdownload(params) {
      const flag = this.isOverdueFilter(params);
      if (flag) return;
      if (this.loading) return;
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 1000);
      const elt = document.createElement('a');
      elt.setAttribute('href', this.urls.sunloginav);
      elt.setAttribute('download', '');
      elt.style.display = 'none';
      document.body.appendChild(elt);
      elt.click();
      this.setCount('av_click');
      document.body.removeChild(elt);
    },
    // 判断是否误点过滤
    isOverdueFilter(params) {
      if (params !== 'point') {
        const one = this.overdueFilter_list.find(item => {
          const { hostn, url } = item;
          return window.location.hostname === hostn && this.appid === url;
        });
        if (one) return true;
        return false;
      }
      return false;
    },
    setCount(action) {
      let app;
      let type;
      let count_hostname;
      if (this.isldstg) {
        app = 'ldstg';
        type = 'ldstg_web';
        count_hostname = LDSCOUNT_ADDR;
      } else {
        app = 'joyfartechtg';
        type = 'joyfartechtg_web';
        count_hostname = JOYCOUNT_ADDR;
      }
      let url = `${count_hostname}/url3?app=${app}&type=${type}&action=${action}`;
      if (this.appid === 'sunloginav') {
        url += '&tg_v=2';
      }
      new Image().src = url;
    },
    setPoint() {
      const url = encodeURI(window.location.href);
      const lst = Cookies.get(`lst_${this.appid}`);
      if (lst !== url) {
        Cookies.set(`lst_${this.appid}`, url);
        const formdata = new FormData();
        formdata.append('url', url);
        /**
         * tg_v = 2的情况
         * 非lds落地页 或者 sunloginav
         */
        if (this.isldstg) {
          if (this.appid === 'sunloginav') {
            formdata.append('tg_v', 2);
          }
        } else {
          formdata.append('tg_v', 3);
        }
        let addr;
        if (this.isldstg) {
          addr = LDSREQ_ADDR;
        } else {
          addr = JOYREQ_ADDR;
        }
        Axios.post(`${addr}/ldstg/report`, formdata);
      }
    }
  }
};
</script>

<style lang="scss">
.el-carousel {
  overflow: hidden;
}
.el-carousel__arrow {
  display: none;
}
.el-carousel__item {
  img {
    width: 100% !important;
    height: 100% !important;
  }
}
.el-carousel__indicators {
  .el-carousel__indicator {
    padding: 4px !important;
    margin: 10px 2px;
  }
  .el-carousel__button {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #afafaf;
  }
  .is-active {
    .el-carousel__button {
      background: #ffffff !important;
    }
  }
}
</style>
<style scoped lang="scss">
.top {
  width: 100%;
  height: 520px;
  background-size: cover;
  background-position: center;
  .one {
    width: 1200px;
    height: 90px;
    margin: 0 auto;
    line-height: 90px;
  }
  .two {
    width: 1100px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    .left {
      width: 540px;
    }
    .right {
      width: 460px;
      .first {
        height: 75px;
        display: flex;
        position: relative;
        > img {
          width: 48px;
          height: 48px;
        }
        > p {
          padding-left: 26px;
          line-height: 48px;
          font-size: 36px;
          font-weight: bold;
        }
        .number {
          width: 110px;
          height: 75px;
          position: absolute;
          right: -50px;
          top: 0;
          background: url(~@/assets/images/times.png);
          background-size: 100% 100%;
          p {
            width: 100px;
            float: right;
            text-align: center;
            &:first-child {
              margin: 25px 0 5px 0;
              font-size: 14px;
              font-weight: 400;
              color: #c3771c;
            }
            &:last-child {
              font-size: 20px;
              font-weight: bold;
              color: #a05700;
            }
          }
        }
      }
      .second {
        height: 120px;
        p {
          width: 230px;
          height: 30px;
          float: left;
          line-height: 30px;
          font-weight: 400;
          span {
            &:first-child {
              font-size: 14px;
            }
            &:last-child {
              font-size: 16px;
            }
          }
        }
      }
      .third {
        padding: 5px 0 30px 0;
        line-height: 24px;
        font-size: 16px;
      }
      .fourth {
        width: 260px;
        height: 60px;
        cursor: pointer;
        background: linear-gradient(91deg, #1bed77 0%, #1ecc86 100%);
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 30px;
          height: 30px;
        }
        span {
          padding-left: 10px;
          font-size: 28px;
          font-weight: 400;
          color: #ffffff;
        }
        &:hover {
          background: linear-gradient(91deg, #36f58a 0%, #3be4a0 100%);
          transform: scale(1.1);
        }
      }
      .fifth {
        height: 62px;
        line-height: 62px;
        font-size: 14px;
        font-weight: 400;
        color: #52545e;
      }
      .sixth {
        width: 474px;
        height: 28px;
        background-size: 100% 100% !important;
      }
    }
    .white {
      color: #ffffff;
      .second {
        span {
          &:first-child {
            color: #aaaaaa;
          }
        }
      }
      .fifth {
        color: #aaaaaa;
      }
      .sixth {
        background: url(~@/assets/images/sixthw.png);
      }
    }
    .dark {
      color: #202125;
      .second {
        span {
          &:first-child {
            color: #52545e;
          }
        }
      }
      .fifth {
        color: #52545e;
      }
      .sixth {
        background: url(~@/assets/images/sixth.png);
      }
    }
  }
}
.tag {
  width: 1100px;
  min-height: 400px;
  margin: 40px auto 0 auto;
  img {
    width: 100%;
  }
}
.desc {
  width: 1100px;
  margin: 40px auto 0 auto;
  .title {
    width: 100%;
    height: 25px;
    border-left: 5px solid #1ceb79;
    line-height: 25px;
    text-indent: 10px;
    font-size: 18px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.9);
  }
  .box {
    margin-top: 20px;
    font-size: 18px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.9);
    line-height: 30px;
    white-space: pre-line;
  }
}
.advantage {
  width: 1100px;
  margin: 40px auto 0 auto;
  .title {
    width: 100%;
    height: 25px;
    border-left: 5px solid #1ceb79;
    line-height: 25px;
    text-indent: 10px;
    font-size: 18px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.9);
  }
  .list {
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    div {
      width: 212px;
      height: 188px;
      border: 1px solid #edf5ff;
      text-align: center;
      img {
        width: 106px;
        height: 106px;
        margin: 20px 0 10px 0;
      }
      p {
        font-size: 20px;
        font-weight: 400;
        color: #3d3d3d;
      }
    }
  }
}
.copyright {
  margin-top: 40px;
  padding-bottom: 120px;
  text-align: center;
  font-size: 13px;
  font-weight: 400;
  color: #999999;
  line-height: 25px;
}
.bottom {
  width: 100%;
  height: 60px;
  position: fixed;
  bottom: 0;
  left: 0;
  background: #ffffff;
  box-shadow: 0px -3px 6px 1px rgba(0, 0, 0, 0.1);
  .box {
    width: 1100px;
    height: 60px;
    margin: 0 auto;
    position: relative;
    .left {
      height: 60px;
      display: flex;
      align-items: center;
      img {
        width: 46px;
        height: 46px;
      }
      span {
        padding-left: 20px;
        font-size: 20px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.9);
      }
    }
    .mid {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      text-align: center;
      line-height: 60px;
      font-size: 24px;
      font-weight: bold;
      color: rgba(0, 0, 0, 0.9);
    }
    .right {
      width: 168px;
      height: 40px;
      cursor: pointer;
      background: linear-gradient(91deg, #1bed77 0%, #1ecc86 100%);
      border-radius: 8px;
      position: absolute;
      right: 0;
      top: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 19px;
        height: 19px;
      }
      span {
        padding-left: 6px;
        font-size: 18px;
        font-weight: 400;
        color: #ffffff;
      }
      &:hover {
        background: linear-gradient(91deg, #36f58a 0%, #3be4a0 100%);
        transform: scale(1.1);
      }
    }
  }
}

.bottomav {
  width: 100%;
  height: 110px;
  position: fixed;
  bottom: 0;
  left: 0;
  background: #ffffff;
  box-shadow: 0px -3px 6px 1px rgba(0, 0, 0, 0.1);
  .box {
    width: 1100px;
    margin: 0 auto;
    .left {
      width: 183px;
      height: 50px;
      margin-top: 30px;
      float: left;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .mid {
      width: 515px;
      height: 70px;
      margin-top: 20px;
      float: left;
      margin-left: 118px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .right {
      width: 168px;
      height: 54px;
      cursor: pointer;
      margin-top: 28px;
      float: right;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
